/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*  @ Snack bar Customization
/* -------------------------------------------------------------------------- */
.mat-mdc-snack-bar-container {
    &.success-snackbar,
    &.error-snackbar {
        .mdc-snackbar__surface {
            .mat-mdc-button {
                color: white !important;
            }
        }
        .mat-mdc-snack-bar-label.mdc-snackbar__label {
            color: white;
            font-weight: 500;
            white-space: pre-wrap;
        }
    }

    &.success-snackbar .mdc-snackbar__surface {
        background: green;
    }

    &.error-snackbar .mdc-snackbar__surface {
        background: red;
    }
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
    border-radius: 4px !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
